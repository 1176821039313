import { IVideoPageHead } from "@/components/Head/video-page";
import { IPaymentReminderData } from "@/components/Modal/PaymentReminder/interface";
import { getVernacularLanguage } from "./language";

export const cleanOcrText = (ocr?: string) => {
  let ocrString = "";
  if (typeof ocr !== "undefined" && ocr !== null && ocr !== "") {
    ocrString = ocr
      .replace(new RegExp("[`]", "g"), "")
      .replace(new RegExp("[_]", "g"), "")
      .replace(/<br[^>]*>/gim, " ")
      .replace(/<img[^>]*>/g, "")
      .trim();
  }
  return ocrString;
};

export const wordCorrection = (string?: string) => {
  let value = "";
  if (typeof string !== "undefined" && string !== null && string !== "") {
    value = string.replace(/triagnle/gi, "triangle");
  }
  return value;
};

export const toTitleCase = (str: string): string => {
  if (!str) {
    return str;
  }
  return str.replace(
    /\w\S*/g,
    (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

export const getSolutionTypeText = (
  hasImageSoln: Boolean,
  hasTextSoln: Boolean,
  hasVideoSoln: Boolean
) => {
  let typeOfSolution = "";
  let typeOfSolutionText = "";
  if (hasVideoSoln && !hasImageSoln && !hasTextSoln) {
    typeOfSolution = "video";
    typeOfSolutionText = "Watch complete video";
  } else if (!hasVideoSoln && !hasImageSoln && hasTextSoln) {
    typeOfSolution = "text";
    typeOfSolutionText = "Step by Step";
  } else if (!hasVideoSoln && hasImageSoln && !hasTextSoln) {
    typeOfSolution = "image";
    typeOfSolutionText = "Step by Step";
  } else if (hasVideoSoln && !hasImageSoln && hasTextSoln) {
    typeOfSolution = "video & text";
    typeOfSolutionText = "Watch complete video";
  } else if (hasVideoSoln && hasImageSoln && !hasTextSoln) {
    typeOfSolution = "video & image";
    typeOfSolutionText = "Watch complete video";
  } else if (!hasVideoSoln && hasImageSoln && hasTextSoln) {
    typeOfSolution = "text & image";
    typeOfSolutionText = "Step by Step";
  } else if (hasVideoSoln && hasImageSoln && hasTextSoln) {
    typeOfSolution = "video, text & image";
    typeOfSolutionText = "Watch complete video";
  }

  return {
    typeOfSolution,
    typeOfSolutionText,
  };
};

const checkRoman = (subStr: string) => {
  let word = subStr;
  const romanArr = ["i", "ii", "iii", "iv", "v", "vi", "vii", "iix", "ix", "x"];
  for (let j = 0; j < romanArr.length; j += 1) {
    if (subStr === romanArr[j]) {
      word = word.toUpperCase();
    }
  }
  return word;
};
// use for first letter capital
export const titleCase = (stre: string) => {
  if (typeof stre !== "string") return "";
  const str = stre.toLowerCase().split(" "); // Make str all lowercase and convert to array
  for (let i = 0; i < str.length; i += 1) {
    // Loop through array elements
    str[i] = checkRoman(str[i]);
    if (str[i].charAt(0) === "(") {
      str[i] =
        str[i].charAt(0) + str[i].charAt(1).toUpperCase() + str[i].slice(2);
    } else {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
  }
  return str.join(" ");
};

export const transcriptToString = (transcript: any) => {
  let text = "";
  if (typeof transcript !== "undefined" && transcript !== "") {
    try {
      text = JSON.parse(transcript)
        .join(" ")
        .replace(/<br[^>]*>/gim, " ");
    } catch (e) {
      text = "";
    }
  }
  return text;
};

export const getViewFrom = () => {
  // removing local storage dependency
  return "DIRECT";
};

const suffixes = ["", "K", "M", "B", "T"];
export const intToString = (value: number) => {
  const suffixNum = Math.floor(("" + value).length / 3);
  let shortValue = parseFloat(
    (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
  );
  if (shortValue % 1 != 0) {
    shortValue = parseFloat(shortValue.toFixed(1));
  }
  return shortValue + suffixes[suffixNum];
};

export const toHHMMSS = (secs: number) => {
  let hours: string | number = Math.floor(secs / 3600);
  let minutes: string | number = Math.floor((secs - hours * 3600) / 60);
  let seconds: string | number = secs - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = hours ? "0" + hours : "";
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours
    ? hours + ":" + minutes + ":" + seconds
    : minutes + ":" + seconds;
};

export const to24HrFormat = (dt: Date) => {
  const hours = dt.getHours();
  const minutes = dt.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const newHours = hours % 12 || 12;
  const newMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${newHours}:${newMinutes} ${ampm}`;
};

export const toDDMMYYYY = (dt: Date) => {
  const date = dt.getDate();
  const month = dt.getMonth() + 1;
  const year = dt.getFullYear();
  return `${date}/${month}/${year}`;
};

export const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d as unknown as number);
}

export const getSubjectLink = (sub: string) => {
  let subLink = "";
  if (typeof sub !== "undefined" && sub !== undefined && sub !== null) {
    const subject = sub.toLowerCase();
    if (subject.includes("phys")) {
      subLink = "-physics";
    } else if (subject.includes("chem")) {
      subLink = "-chemistry";
    } else if (subject.includes("bio")) {
      subLink = "-biology";
    } else if (subject.includes("general intelligence and reasoning ability")) {
      subLink = "-general-intelligence-and-reasoning-ability";
    } else if (subject.includes("general knowledge and aptitude")) {
      subLink = "-general-knowledge-and-aptitude";
    } else if (subject.includes("general knowledge")) {
      subLink = "-general-knowledge";
    } else if (subject.includes("general awareness")) {
      subLink = "-general-awareness";
    } else if (subject.includes("general studies")) {
      subLink = "-general-studies";
    } else if (subject.includes("general science")) {
      subLink = "-general-science";
    } else if (subject.includes("geogra")) {
      subLink = "-geography";
    } else if (subject.includes("guidance")) {
      subLink = "-guidance";
    } else if (subject.includes("english gramm")) {
      subLink = "-english-grammar";
    } else if (subject.includes("engl")) {
      subLink = "-english";
    } else if (subject.includes("business studies")) {
      subLink = "-business-studies";
    } else if (subject.includes("hist")) {
      subLink = "-history";
    } else if (subject.includes("accoun")) {
      subLink = "-accounts";
    } else if (subject.includes("econo")) {
      subLink = "-economics";
    } else if (subject.includes("reas")) {
      subLink = "-reasoning";
    } else if (subject.includes("toppers talk")) {
      subLink = "-toppers-talk";
    } else if (subject.includes("motivational videos")) {
      subLink = "-motivational-videos";
    } else if (subject.includes("social science")) {
      subLink = "-social-science";
    } else if (subject.includes("political science")) {
      subLink = "-political-science";
    } else if (subject.includes("computer science")) {
      subLink = "-computer-science";
    } else if (subject.includes("science")) {
      subLink = "-science";
    } else if (subject.includes("hindi")) {
      subLink = "-hindi";
    } else if (subject.includes("current affair")) {
      subLink = "-current-affairs";
    } else if (subject.includes("ncert_neet_video")) {
      subLink = "-ncert_neet_videos";
    } else if (subject.includes("technical math")) {
      subLink = "-technical-maths";
    } else if (subject.includes("advanced math")) {
      subLink = "-advanced-math";
    } else if (subject.includes("cdp")) {
      subLink = "-cdp";
    } else if (subject.includes("environmental studies")) {
      subLink = "-environmental-studies";
    } else if (subject.includes("civic")) {
      subLink = "-civics";
    } else if (subject.includes("sanskrit")) {
      subLink = "-sanskrit";
    } else if (subject.includes("announcement")) {
      subLink = "-announcement";
    } else if (subject.includes("child development and pedagogy")) {
      subLink = "-child-development-and-pedagogy";
    } else if (subject.includes("computer applications")) {
      subLink = "-computer-applications";
    } else if (subject.includes("evs")) {
      subLink = "-evs";
    } else if (subject.includes("all")) {
      subLink = "-all";
    }
  }
  return subLink;
};

export const countUpvotes = (totalLikes: number) => {
  if (!totalLikes) {
    return 1;
  }
  return totalLikes > 10000 ? 10000 + (totalLikes % 1000) : totalLikes;
};

export const getMetaDescription = (
  isBio: Boolean,
  typeOfSolutionText: string,
  ocrTextClean: string,
  subjectName: string,
  questionClass: number,
  chapter: string
) => {
  return isBio
    ? `${typeOfSolutionText} answer for “${ocrTextClean?.slice(
      0,
      61
    )}” of ${subjectName} Class ${questionClass}th. Get FREE solutions to all questions from chapter ${chapter}.`
    : ocrTextClean?.slice(0, 158);
};

export const getHeadTitle = (ocrTextClean: string, text_solution: any) => {
  let headTitle = ocrTextClean;
  const opt1CleanOcr = cleanOcrText(text_solution.opt_1);
  const opt2CleanOcr = cleanOcrText(text_solution.opt_2);
  const opt3CleanOcr = cleanOcrText(text_solution.opt_3);
  const opt4CleanOcr = cleanOcrText(text_solution.opt_4);

  const allOptions = `${opt1CleanOcr}${opt2CleanOcr}${opt3CleanOcr}${opt4CleanOcr}`;
  if (allOptions.length > 150 && opt1CleanOcr) {
    headTitle = opt3CleanOcr;
  }
  return headTitle?.replace(/(?:\r\n|\r|\n)/g, " ");
};

export const getMetaKeyword = (value: string, mode?: string) => {
  let str = value;
  if (typeof str !== "undefined" && str !== null && str !== "") {
    if (typeof mode !== "undefined" && mode === "class") {
      str = `Class ${str.replace(/,+/g, " ").replace(/(<([^>]+)>)/gi, "")}`;
    } else {
      str = str.replace(/,+/g, " ").replace(/(<([^>]+)>)/gi, "");
    }
  } else {
    str = "";
  }
  return str;
};

export const getCanonicalUrl = (
  isVernacular: Boolean,
  questionSubject: string,
  studentId: number,
  questionId: number,
  canonicalUrl: string
) => {
  if (isVernacular) {
    if (questionSubject.toLowerCase().includes("toppers talk")) {
      return `c/${canonicalUrl}`;
    }
    return `/qa-${getVernacularLanguage(studentId)}/${questionId}`;
  }
  if (questionId == 646581677) {
    return "https://www.doubtnut.com/question-answer/in-a-survey-of-60-people-it-was-found-that-25-people-read-newspaper-h-26-read-newspaper-t-26-read-ne-1";
  }
  return `/question-answer${getSubjectLink(questionSubject)}/${canonicalUrl}`;
};

export const toBase64 = (str: string) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

export const fixUnclosedHtmlTags = (html: string) => {
  return html
    .replace(/,br/g, "<br")
    .replace(/br\./g, "br>")
    .replace(/\/br\/\./g, "br/>")
    .replace(/\.img/, "<img");
};

export const fixHtmlTags = (html: string) => {
  html = wordCorrection(html);
  html = fixUnclosedHtmlTags(html);
  const matches = html.match(/<img[^>]*>/g);
  if (!matches) {
    return html;
  }
  for (let match of matches) {
    const newMatch = match
      .replace("<img", `<img loading="lazy"`)
      .replace(
        /width="\d+%"/g,
        `width="80%" class="mx-auto" style="background:white;"`
      );
    html = html.replace(match, newMatch);
  }
  if (!html.includes("alt=")) {
    html = html.replace(/<img/g, `<img alt="ocr_image"`);
  }
  return html;
};

export const getCurrentDate = () => {
  // const dt = new Date();
  // const dtStr = `${dt.getDate().toString().padStart(2, "0")}/${(dt.getMonth() +1).toString().padStart(2, "0")}/${dt.getFullYear()}`;

  // return dtStr;
  return "21/07/2023";
};

export const removeEmptyKeys = (obj: { [key: string]: any }) => {
  for (const key in obj) {
    if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
      delete obj[key];
    }
  }
};

export const cleanTitleForSEO = (
  titlePrefix: string,
  title?: string
): string | undefined => {
  if (!title) {
    return "Doubtnut";
  }
  if (title.length < 15) {
    title = `${titlePrefix || "[Solved] "}${title}`;
    return title.slice(0, 70);
  }
  return [titlePrefix, title].join("").slice(0, 70);
};

export const getSearchSnippetTextId = (videoPageHeadProps: IVideoPageHead) => {
  if (
    (videoPageHeadProps.hasOptions || videoPageHeadProps.isTextAnswered)
  ) {
    return "#acceptedAnswer";
  }
  return "#ocr-text";
};

export const isCSRRequest = (url?: string) => {
  if (!url) {
    return false;
  }
  return url.startsWith("/_next");
};

export const removeHtmlTags = (html?: string) => {
  if (!html) {
    return "";
  }
  return html.replace(/<[^>]*>?/gm, "");
}

export const getCookieByName = (name: string) => {
  return global?.window?.document.cookie.split(";").find((item) => item.trim().startsWith(`${name}=`))?.split("=")[1];
}

export function getPaymentReminderDataLS() {
  return JSON.parse(localStorage?.getItem("paymentReminderData") || "{}");
}

export function setPaymentReminderDataLS(dataObj: IPaymentReminderData) {
  return localStorage?.setItem("paymentReminderData", JSON.stringify(dataObj));
}

export function removePaymentReminderDataLS() {
  return localStorage?.removeItem("paymentReminderData");
}

export const slugify = (str: string) => {
  str = str.toLowerCase();
  str = str.replace(/\(|\)/g, " ").replace(/[^a-zA-Z0-9\u0900-\u097F\s]/g, "").trim();
  str = str.split(" ").join("-").replace(/--/g, "-");
  return str;
}

export const generateInternalDeeplinkFromBranchDeeplink = (deeplink: string) => {
  return deeplink.replace("doubtnut.app.link", "dl.doubtnut.com/l");
}

export const setHtmlAttribute = (key: string, value: string) => {
  document.querySelector("html")?.setAttribute(key, value);
}

export const getVideoTimeStamps = (titleWithTimestamps: string) => { 
  return titleWithTimestamps.match(/(\d{2}:\d{2}|:\d{2}) - (\d{2}:\d{2}|:\d{2})/gi) || []
}