import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppState } from "..";
import { getUUID } from "@/utils/auth";
import { IOffCanvasPayloadAction, IOffCanvasState, IOffCanvasStateAction, OffCanvasName } from "./interface";

const initialState: IOffCanvasState = {
  name: null,
  data: {
    LOGIN: {
      method: null,
      mobile: "",
      screen: "MOBILE",
      sessionId: "",
      verified: false,
      editable: true,
    },
    USER_DETAILS_INPUT: {
      fields: [],
      cta: [
        {
          text: "",
        },
        {
          text: "",
        },
      ],
    },
    CAMERA: {
      cameraBSTabIndex: 1,
      img: "",
      text: null,
      questionId: null,
      searching: false,
      help: false,
      matchedQuestions: [],
      blob: null,
    },
    MOBILE_CONFIRMATION: {
      mobile: ""
    },
  },
  deferActionPayload: {},
  nextActions: [],
  lastSuccessfulAction: null,
  allActionsCompleted: false,
};

export const offCanvasSlice = createSlice({
  name: "offCanvas",
  initialState,
  reducers: {
    setDeferActionPayload(state, action: PayloadAction<any>) {
      state.deferActionPayload = action.payload;
    },
    updateOffCanvasView(state, action: PayloadAction<IOffCanvasStateAction | IOffCanvasStateAction[]>) {
      const currentActionPayload = Array.isArray(action.payload) ? action.payload[0] : action.payload;
      const nextActions = Array.isArray(action.payload) ? action.payload.slice(1) : [];

      state.name = currentActionPayload.name;
      // @ts-ignore
      state.data = {
        ...state.data,
        ...currentActionPayload.data,
      }
      state.allActionsCompleted = false;
      state.nextActions = nextActions as IOffCanvasState[];
      if (currentActionPayload.name === "LOGIN" && state.data.LOGIN.method === "TRUECALLER") {
        window.location.href = `truecallersdk://truesdk/web_verify?${new URLSearchParams({
          requestNonce: getUUID(),
          partnerKey: "QgeVR7f6d02d689534b2b9270158e5c521bd5",
          partnerName: "Doubtnut",
          lang: "en",
          loginPrefix: "getstarted",
          loginSuffix: "login",
          ctaPrefix: "continuewith",
          ctaColor: "#eb532c",
          ctaTextColor: "#ffffff",
          btnShape: "rect",
          skipOption: "useanothernum",
          ttl: "8000",
          type: "btmsheet",
        }).toString()}`;
      }
    },
    resetOffCanvasView(state) {
      state.name = null;
      state.lastSuccessfulAction = null;
      state.deferActionPayload = {};
      state.allActionsCompleted = false;
      state.nextActions = [];
      state.data = initialState.data; // This is purposely avoided to retain data. If you want to reset data, use resetOffCanvasViewPayload. Maybe this can be enabled.
    },
    nextOffCanvasView(state, action: PayloadAction<IOffCanvasPayloadAction | {}>) {
      if (state.nextActions.length) {
        state.lastSuccessfulAction = state.name;
        const newAction = state.nextActions.shift();
        if (!newAction) {
          return;
        }
        state.name = newAction.name;
        Object.keys(newAction.data).forEach((key) => {
          state.data = {
            ...state.data,
            [key]: {
              ...(state.data as any)[key],
              ...(newAction.data as any)[key],
            },
          }
        });
      } else {
        state.name = null;
        state.data = initialState.data;
      }

      const payloadDataKeys = Object.keys(action.payload);
      if (!state.nextActions.length) {
        state.allActionsCompleted = true;
        state.lastSuccessfulAction = null;
      };

      payloadDataKeys.forEach((key) => {
        state.data = {
          ...state.data,
          [key]: {
            ...(state.data as any)[key],
            ...(action.payload as any)[key],
          },
        }
      });
    },
    updateOffCanvasViewPayload(state, action: PayloadAction<IOffCanvasPayloadAction>) {
      Object.keys(action.payload).forEach((key) => {
        state.data = {
          ...state.data,
          [key]: {
            ...(state.data as any)[key],
            ...(action.payload as any)[key],
          },
        }
      });
    },
    resetOffCanvasViewPayload(state, action: PayloadAction<OffCanvasName>) {
      if (!action.payload) {
        state.data = initialState.data;
        return;
      }
      state.data = {
        ...state.data,
        [String(action.payload)]: initialState.data[action.payload as keyof IOffCanvasPayloadAction],
      }
    },
  },
});

export default offCanvasSlice.reducer;

export const offCanvasInitialState = initialState;

export const selectOffCanvasView = (state: AppState) => state.offCanvas;
export const selectOffCanvasName = (state: AppState) => state.offCanvas.name;
export const selectOffCanvasPayload = (state: AppState) => state.offCanvas.data;
export const selectOffCanvasNextAction = (state: AppState) => state.offCanvas.nextActions?.[0] || null;
export const selectOffCanvasLastSuccessfulAction = (state: AppState) => state.offCanvas.lastSuccessfulAction;
export const selectOffCanvasAllActionsCompleted = (state: AppState) => state.offCanvas.allActionsCompleted;

export const selectLoginIntent = (state: AppState) => state.offCanvas.name === "LOGIN";
export const selectOTPLoginIntent = (state: AppState) => state.offCanvas.name === "LOGIN" && state.offCanvas.data.LOGIN.method === "OTP";
export const selectTruecallerLoginIntent = (state: AppState) => state.offCanvas.name === "LOGIN" && state.offCanvas.data.LOGIN.method === "TRUECALLER";
export const selectLoginIntentPayload = (state: AppState) => state.offCanvas.data.LOGIN;
export const selectLoginIntentPayloadMethod = (state: AppState) => state.offCanvas.data.LOGIN.method || null;
export const selectLoginIntentPayloadMobile = (state: AppState) => state.offCanvas.data.LOGIN.mobile || "";
export const selectLoginIntentPayloadSessionId = (state: AppState) => state.offCanvas.data.LOGIN.sessionId || "";
export const selectLoginIntentPayloadScreen = (state: AppState) => state.offCanvas.data.LOGIN.screen || "MOBILE";
export const selectLoginIntentPayloadVerified = (state: AppState) => state.offCanvas.data.LOGIN.verified || false;
export const selectLoginIntentPayloadEditable = (state: AppState) => state.offCanvas.data.LOGIN.editable || false;

export const selectBannerIntent = (state: AppState) => state.offCanvas.name === "BANNER";
export const selectNotificationIntent = (state: AppState) => state.offCanvas.name === "NOTIFICATION";
export const selectAmountInputIntent = (state: AppState) => state.offCanvas.name === "AMOUNT_INPUT";
export const selectMobileConfirmationIntent = (state: AppState) => state.offCanvas.name === "MOBILE_CONFIRMATION";
export const selectCouponIntent = (state: AppState) => state.offCanvas.name === "COUPON";
export const selectVoiceSearchIntent = (state: AppState) => state.offCanvas.name === "VOICE_SEARCH";
export const selectHamburgerIntent = (state: AppState) => state.offCanvas.name === "HAMBURGER";
export const selectSocialShareIntent = (state: AppState) => state.offCanvas.name === "SOCIAL_SHARE";
export const selectDislikeFeedbackIntent = (state: AppState) => state.offCanvas.name === "DISLIKE_FEEDBACK";
export const selectPlaylistIntent = (state: AppState) => state.offCanvas.name === "PLAYLIST";
export const selectExploreFiltersIntent = (state: AppState) => state.offCanvas.name === "EXPLORE_FILTERS";

export const selectUserDetailsInputIntent = (state: AppState) => state.offCanvas.name === "USER_DETAILS_INPUT";
export const selectUserDetailsInputIntentPayload = (state: AppState) => state.offCanvas.data.USER_DETAILS_INPUT;
export const selectDeferActionPayload = (state: AppState) => state.offCanvas.deferActionPayload;

export const selectCameraIntent = (state: AppState) => state.offCanvas.name === "CAMERA";
export const selectCameraBSTabIndex = (state: AppState) => state.offCanvas.data.CAMERA.cameraBSTabIndex;
export const selectCameraBlob = (state: AppState) => state.offCanvas.data.CAMERA.blob;
export const selectAskResults = (state: AppState) => state.offCanvas.data.CAMERA.matchedQuestions;
export const selectQuestionAskedImage = (state: AppState) => state.offCanvas.data.CAMERA.img;
export const selectQuestionAskedText = (state: AppState) => state.offCanvas.data.CAMERA.text;
export const selectAskQuestionId = (state: AppState) => state.offCanvas.data.CAMERA.questionId;
export const selectSearching = (state: AppState) => state.offCanvas.data.CAMERA.searching;
export const selectCameraHelp = (state: AppState) => state.offCanvas.data.CAMERA.help;
export const selectCameraReady = (state: AppState) => !!(state.offCanvas.data.CAMERA.matchedQuestions.length || state.offCanvas.data.CAMERA.help);

export const selectMobileConfirmationPayloadMobile = (state: AppState) =>  state.offCanvas.data.MOBILE_CONFIRMATION.mobile || "";

export const {
  updateOffCanvasView,
  resetOffCanvasView,
  nextOffCanvasView,
  updateOffCanvasViewPayload,
  resetOffCanvasViewPayload,
  setDeferActionPayload,
} = offCanvasSlice.actions;
